/**
 * TODO: RESOLVE props issues with <a> output
 */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby-plugin-react-i18next";
import styled, { css } from "styled-components";

const buttonFontColor = {
  primary: "#fff",
  secondary: "#3b4252",
  simple: "#4c566a",
  subtle: "#5e81ac",
};
const buttonBgColor = {
  primary: "#04805f",
  secondary: "#FFC233",
  simple: "rgba(236, 239, 244, 0.45)",
  subtle: "transparent",
};
const buttonBgColorHover = {
  primary: "#3BD1A9",
  secondary: "#FFDA85",
  simple: "rgb(229 235 246)",
  subtle: "rgba(236, 239, 244, 0.65)",
};

const BaseButton = styled(Link)`
  align-items: center;
  background: none;
  background-color: ${({ ghost, variant }) => !ghost && buttonBgColor[variant]};
  border-radius: 0.75em;
  border: none;
  color: ${({ variant }) => buttonFontColor[variant]}!important;
  cursor: pointer;
  display: inline-flex;
  line-height: 1.15;
  padding: ${({ variant }) =>
    variant === "simple" ? "0 0.25em" : "0.4em 1em"};
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:active,
  &:focus,
  &:hover,
  &:visited {
    outline: none;
  }

  transition: background-color 400ms ease-in-out, border-color 400ms ease-in-out,
    color 400ms ease-in-out;
  ${({ ghost, variant }) =>
    ghost &&
    css`
      border: 0.0625em solid ${buttonBgColor[variant]};
    `};
  &:hover,
  &:active {
    background-color: ${({ variant }) => buttonBgColorHover[variant]};
    border-color: ${({ ghost, variant }) =>
      ghost && buttonBgColorHover[variant]};
    ${({ ghost, variant }) => {
      if (!ghost && (variant === "primary" || variant === "secondary")) {
        return css`
          color: rgb(250, 250, 250);
        `;
      }
    }}
  }
`;

const A = ({ children, to, ghost, variant, ...passProps }) => (
  <BaseButton to={to} ghost={+ghost} variant={variant} {...passProps}>
    {children}
  </BaseButton>
);

A.defaultProps = {
  to: "",
};

A.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
};

const Button = ({ children, ghost, to, variant, ...passProps }) => (
  <A ghost={ghost} to={to} variant={variant} {...passProps}>
    {children}
  </A>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary", "simple", "subtle"]),
};

Button.defaultProps = {
  to: "",
  variant: "simple",
};

export default Button;
