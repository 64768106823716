import styled, { css } from "styled-components";
import ms from "../../util/ms";

const Subline = styled.p`
  ${({ theme, bright }) => bright && `color: ${theme.colors.brighterColor};`};
  font-size: ${ms(1)};
  transition: color 1ms ease-in-out;
  margin-top: 0;
  margin-bottom: 1em;
  ${({ theme }) => theme.media.phoneLandscape`
    font-size: ${ms(2)};
  `};
  ${({ subGrid }) =>
    subGrid &&
    css`
      padding-left: 0.25em;
      margin-bottom: -1em;
    `}
  ${({ centered }) => centered && `text-align: center;`}
`;

const ContactSubline = styled(Subline)`
  ${({ theme }) => theme.media.tabletLandscape`
    padding-left: 7rem;
    padding-right: 7rem;
  `};
  &:first-of-type {
    margin-bottom: 2em;
  }
`;

export default Subline;
export { ContactSubline };
