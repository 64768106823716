import styled from "styled-components";
import AudioPlayer from "react-h5-audio-player";

const Player = styled(AudioPlayer)`
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  width: 100%;
  background: ${({ theme }) => theme.backgroundColors.codeColor};
  border-radius: 1em;
  padding: 1rem;
  grid-area: ${(props) => props.areaType};

  svg {
    vertical-align: initial;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }

  .rhap_footer {
    padding-top: 0.75em;
    padding-left: 0.25em;
  }
  .rhap_main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .rhap_horizontal {
    flex-direction: row;

    .rhap_controls-section {
      margin-left: 8px;
    }
  }
  .rhap_horizontal-reverse {
    flex-direction: row-reverse;

    .rhap_controls-section {
      margin-right: 8px;
    }
  }
  .rhap_progress-section {
    display: flex;
    flex: 1 1 80%;
    align-items: center;
  }
  .rhap_progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1 0 auto;
    align-self: center;
    margin: 0 calc(10px + 1%);
    cursor: pointer;
    -webkit-user-select: none;
  }
  .rhap_progress-container:focus:not(:focus-visible) {
    outline: 0;
  }

  .rhap_progress-bar {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 5px;
    background-color: #dddddd;
    border-radius: 2px;
  }

  .rhap_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #868686;
    border-radius: 2px;
  }

  .rhap_progress-bar-show-download {
    background-color: rgba(221, 221, 221, 0.5);
  }

  .rhap_download-progress {
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: #dddddd;
    border-radius: 2px;
  }

  .rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    top: -8px;
    background: #868686;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
  }

  .rhap_controls-section {
    display: flex;
    flex: 1 0 auto;
  }

  .rhap_main-controls {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rhap_main-controls-button {
    color: #868686;
    font-size: 35px;
    width: 35px;
    height: 35px;
  }

  .rhap_play-pause-button {
    font-size: 40px;
    width: 40px;
    height: 40px;
  }

  /* Utils */
  .rhap_button-clear {
    background-color: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
      transition-duration: 0.2s;
    }
    &:active {
      opacity: 0.95;
    }
    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }
`;

export default Player;
