import React from "react";
import PropTypes from "prop-types";
import { css, ThemeProvider, createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import em from "../../util/em";

import "@fontsource/rubik/400.css";
import "@fontsource/rubik/700.css";

// TODO: Source these out of the component
const createQuery = (...query) => (...rules) => css`
  @media ${css(...query)} {
    ${css(...rules)};
  }
`;

const breakpoints = {
  phoneLandscapeUpperBoundary: 480,
  tabletPortraitLowerBoundary: 600,
  tabletLandscapeLowerBoundary: 900,
  desktopLowerBoundary: 1200,
  desktopPlusLowerBoundary: 1800,
};

const theme = {
  font: {
    sans: "Rubik, sans-serif",
    serif: '"Above the Beyond", serif',
    monospace: "monospace",
  },
  fontSizes: {
    base: "1em",
    nav: "1.25rem",
    logo: "2rem",
    mobileLogo: "1.35rem",
  },
  colors: {
    baseColor: "rgb(76, 86, 106)",
    brighterColor: "rgb(236, 239, 244)",
    borderBottomColor: "rgb(216, 222, 233)",
    heartAccentColor: "rgb(195, 91, 95)",
    dividerColorBright: "rgb(255, 255, 255)",
    dividerColorLight: "rgb(248, 249, 251)",
    dividerColorDark: "rgb(46, 52, 64)",
    dividerColorGray: "rgb(229, 231, 234)",
    decorationColor: "rgb(229, 233, 240)",
    emailColor: "rgb(93, 191, 171)",
    iconColor: "rgb(60, 143, 124)",
    inlineLinkColor: "rgb(93, 191, 171)",
  },
  backgroundColors: {
    baseColor: "rgb(255, 255, 255)",
    footerColor: "rgb(251, 251, 252)",
    hoverColor: "rgba(236, 239, 244, 0.6)",
    headerShadowColor: "rgba(20,20,20 , 0.4)",
    codeColor: "rgb(236, 239, 244)",
    lightColor: "rgb(248, 249, 251)",
    grayColor: "rgb(229, 231, 234)",
    darkColor: "rgb(46, 52, 64)",
  },
  media: {
    phonePortrait: createQuery`(max-width: ${em(
      breakpoints.phoneLandscapeUpperBoundary - 1,
    )})`,
    phoneLandscape: createQuery`(min-width: ${em(
      breakpoints.phoneLandscapeUpperBoundary,
    )})`,
    tabletPortrait: createQuery`(min-width: ${em(
      breakpoints.tabletPortraitLowerBoundary,
    )})`,
    tabletLandscape: createQuery`(min-width: ${em(
      breakpoints.tabletLandscapeLowerBoundary,
    )})`,
    desktop: createQuery`(min-width: ${em(breakpoints.desktopLowerBoundary)})`,
    desktopPlus: createQuery`(min-width: ${em(
      breakpoints.desktopPlusLowerBoundary,
    )})`,
    breakpoints,
  },
};

const GlobalStyle = createGlobalStyle`
  ${reset}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  a, a:hover, a:visited {
      color: inherit;
      text-decoration: none;
  }
  body {
    font-family: ${theme.font.sans};
    font-size: ${theme.fontSizes.base};
		line-height: 1.5;
    color: ${theme.colors.baseColor};
  }
`;

const Root = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);

Root.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Root;
