import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import ms from "../../util/ms";

const RefLink = styled(Link)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.inlineLinkColor}!important;
  text-decoration: underline;
  text-decoration-thickness: 0.15em;
  text-decoration-color: ${({ theme }) =>
    theme.colors.inlineLinkColor}!important;
  transition: text-decoration 200ms ease-in-out 0s, color 200ms ease-in-out 0s;
  &:hover {
    color: ${({ theme }) => theme.colors.brighterColor}!important;
    text-decoration: underline;
    text-decoration-thickness: 0.15em;
    text-decoration-color: ${({ theme }) =>
      theme.colors.brighterColor}!important;
  }
`;

const PlayListLinkExternal = styled.a`
  align-items: center;
  border-color: ${({ theme }) => theme.colors.inlineLinkColor}!important;
  border-radius: 1em;
  border: 0.0625em solid;
  color: ${({ theme }) => theme.colors.inlineLinkColor}!important;
  display: flex;
  font-size: ${ms(2)};
  height: 0;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  transition: color 200ms ease-in-out 0s, background-color 200ms ease-in-out 0s;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.colors.brighterColor}!important;
    background-color: ${({ theme }) => theme.colors.baseColor};
  }
  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TextLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes.nav};
  border-radius: 0.25em;
  padding: 0.5em;
  &:active,
  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.backgroundColors.hoverColor};

    > svg {
      filter: opacity(1) grayscale(0);
      transition: filter 200ms ease-in-out 0s;
    }
  }
  transition: background-color 100ms ease-in-out 0s;

  > svg {
    width: 2.5rem;
    margin: 0;
    height: 100%;
    transition: filter 300ms ease-in-out 0s;

    ${({ theme }) => theme.media.tabletPortrait`
      filter: opacity(0.7) grayscale(1);
      width: 1.25rem;
      margin: unset;
    `};
  }
`;

const FlagLink = styled(TextLink)`
  ${({ theme }) => theme.media.tabletLandscape`
    display: flex;
  `};
`;

const LogoLink = styled(Link)`
  fill: ${({ theme }) => theme.colors.baseColor};
  &:active,
  &:focus,
  &:hover {
    fill: gray;
  }
  transition: fill 100ms ease-in-out 0s;
`;

const NavigationLinks = styled.div`
  display: none;
  justify-content: space-between;

  * {
    margin: 0 0.556em;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  ${({ theme }) => theme.media.tabletLandscape`
		display: flex;
	`};
`;

const SitemapLink = styled.a`
  display: block;
  padding: 0.4em 0;
  font-size: 0.9em;
  color: ${({ theme }) => theme.colors.baseColor};
  transition: color 100ms ease-in-out;

  &:active,
  &:focus,
  &:hover {
    color: gray;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

/**
 * I hate that !important, however chrome will not
 * inherit the style color for the first element inside
 * of the <Subline/>. Looks like a bug in chrome 88
 **/
const InlineLink = styled.a`
  display: inline-block;
  position: relative;
  font-size: ${ms(1)};
  ${({ theme }) => theme.media.phoneLandscape`
     font-size: ${ms(2)};
   `};
  color: ${({ theme }) => theme.colors.inlineLinkColor} !important;
  padding: 0 0.35rem;

  &:after {
    position: absolute;
    bottom: -0.15rem;
    content: "";
    display: block;
    height: 0.2rem;
    width: 0;
    left: 50%;
    opacity: 0;
    background: ${({ theme }) => theme.colors.inlineLinkColor};
    transition: width 400ms ease-in-out 0s, left 400ms ease-in-out 0s,
      opacity 400ms ease-in-out 100ms;
  }
  &:hover:after {
    width: 100%;
    left: 0;
    opacity: 1;
    transition: width 200ms ease-in-out 0s, left 200ms ease-in-out 0s,
      opacity 300ms ease-in-out 0s;
  }
`;

const SitemapLinkExternal = ({ children, href }) => (
  <SitemapLink target="_blank" rel="noreferrer" href={href}>
    {children}
  </SitemapLink>
);

const TextLinkExternal = ({ children, href, ...props }) => (
  <InlineLink target="_blank" rel="noreferrer" href={href} {...props}>
    {children}
  </InlineLink>
);

const MailLinkExternal = ({ children, user, domain, ...props }) => (
  <InlineLink href={`mailto:${user}@${domain}`}>{children}</InlineLink>
);

export {
  FlagLink,
  LogoLink,
  MailLinkExternal,
  NavigationLinks,
  PlayListLinkExternal,
  RefLink,
  SitemapLinkExternal,
  TextLink,
  TextLinkExternal,
};
