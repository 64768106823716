import styled from "styled-components";

const Text = styled.div`
  color: ${({ theme }) => theme.colors.baseColor};
  ${({ theme, bright }) => bright && `color: ${theme.colors.brighterColor};`};

  text-align: center;

  ${({ theme, verticalOnly }) => theme.media.tabletLandscape`
    text-align: ${!verticalOnly && "start"};
  `};

  ${({ reverseRowOrder }) =>
    reverseRowOrder &&
    `
    order: 1;
    align-self: flex-start;
    padding-left: 2em;
  `};
`;

const Pre = styled.pre`
  background-color: ${({ theme }) => theme.backgroundColors.codeColor};
  overflow: auto;
  white-space: pre;
`;

const Code = styled.code`
  font-family: ${({ theme }) => theme.font.monospace};
  font-weight: 500;
  font-size: 0.85em;
  padding: 0.2em 0.4em;
  background-color: ${({ theme }) => theme.backgroundColors.codeColor};
  border-radius: 4px;

  ${Pre} & {
    display: block;
    padding: 1em;
    font-size: 0.9375em;
  }
`;

export { Pre, Code };
export default Text;
