import styled from "styled-components";
import imageMask from "../../../assets/images/svg/headshot-mask.svg";

/**
 * applying ridiculous styles to deal with firefox + safari
 */
const ImageMask = styled(imageMask)`
  position: absolute;
  top: -3px;
  left: -3px;
  z-index: 1;
  height: calc(100% + 3px);
  width: 102%;
`;

export default ImageMask;
