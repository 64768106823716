import React from "react";
import { StyledSpan } from "../Atoms";
import { MailLinkExternal } from "../SubComponents";

const EmailContainer = ({ userString, mailString }) => {
  const User = userString;
  const Mail = mailString;
  return (
    <>
      <MailLinkExternal user={User} domain={Mail}>
        <StyledSpan>{User}</StyledSpan>
        <StyledSpan>@</StyledSpan>
        <StyledSpan>{Mail}</StyledSpan>
      </MailLinkExternal>
    </>
  );
};

export default EmailContainer;
