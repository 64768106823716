const navigationItems = [
  { title: "About Me", url: "/about" },
  { title: "Rates", url: "/rates" },
  { title: "Contact", url: "/contact" },
];
const navigationItemsMobile = [
  { title: "Home", url: "/" },
  { title: "About Me", url: "/about" },
  { title: "Rates", url: "/rates" },
  { title: "Contact", url: "/contact" },
];

export { navigationItemsMobile };
export default navigationItems;
