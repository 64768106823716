import React from "react";
import PropTypes from "prop-types";

import Root from "./Root";
import Header from "./Header";
import Page from "./Page";
import Footer from "./Footer";

// Root contains styling
const BaseLayout = ({ children }) => (
  <Root>
    <Header />
    <Page>{children}</Page>
    <Footer />
  </Root>
);

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
