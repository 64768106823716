import { SITEMAP_LINKS, INTERNAL_LINKS } from "../../constants/linkdata";

const sitemapCategories = [
  {
    name: "Socials",
    url: "",
    links: [
      {
        title: SITEMAP_LINKS.SPOTLIGHT.TITLE,
        url: SITEMAP_LINKS.SPOTLIGHT.URL,
        external: true,
      },
      {
        title: SITEMAP_LINKS.LINKEDIN.TITLE,
        url: SITEMAP_LINKS.LINKEDIN.URL,
        external: true,
      },
      {
        title: SITEMAP_LINKS.YOUTUBE.TITLE,
        url: SITEMAP_LINKS.YOUTUBE.URL,
        external: true,
      },
      {
        title: SITEMAP_LINKS.GFTB.TITLE,
        url: SITEMAP_LINKS.GFTB.URL,
        external: true,
      },
    ],
  },
  {
    name: "Agencies",
    url: "",
    links: [
      {
        title: SITEMAP_LINKS.CHATTERBOX.TITLE,
        url: SITEMAP_LINKS.CHATTERBOX.URL,
        external: true,
      },
      {
        title: SITEMAP_LINKS.LONDON_VOICEOVER.TITLE,
        url: SITEMAP_LINKS.LONDON_VOICEOVER.URL,
        external: true,
      },
      {
        title: SITEMAP_LINKS.OUTSPOKEN.TITLE,
        url: SITEMAP_LINKS.OUTSPOKEN.URL,
        external: true,
      },
      {
        title: SITEMAP_LINKS.VOICEOVER_GALLERY.TITLE,
        url: SITEMAP_LINKS.VOICEOVER_GALLERY.URL,
        external: true,
      },
      {
        title: SITEMAP_LINKS.VOICE_SQUAD.TITLE,
        url: SITEMAP_LINKS.VOICE_SQUAD.URL,
        external: true,
      },
      {
        title: SITEMAP_LINKS.VOQUENT.TITLE,
        url: SITEMAP_LINKS.VOQUENT.URL,
        external: true,
      },
    ],
  },
  {
    name: "Site",
    url: "/",
    links: [
      { title: INTERNAL_LINKS.ABOUT.TITLE, url: INTERNAL_LINKS.ABOUT.URL },
      { title: INTERNAL_LINKS.RATES.TITLE, url: INTERNAL_LINKS.RATES.URL },
      { title: INTERNAL_LINKS.CONTACT.TITLE, url: INTERNAL_LINKS.CONTACT.URL },
      { title: INTERNAL_LINKS.IMPRINT.TITLE, url: INTERNAL_LINKS.IMPRINT.URL },
    ],
  },
];

export default sitemapCategories;
