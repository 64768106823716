import styled from "styled-components";

const DecorationCanvas = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export default DecorationCanvas;
