import React from "react";
import { Link, useTranslation, Trans } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import Heart from "../../assets/images/svg/heart.svg";
import sitemapCategories from "../References/sitemapCategories";

import { Content, SitemapLinkExternal } from "../SubComponents";

const Sitemap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;

  ${({ theme }) => theme.media.tabletPortrait`
    align-items: unset;
    margin-top: 0;
    margin-bottom: 0;
  `};
`;
const SitemapCategory = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderBottomColor};
  transition: border-bottom 100ms ease-in-out;
  margin-bottom: 1em;
  width: 70%;
  user-select: none;

  ${({ theme }) => theme.media.tabletPortrait`
    width: unset;
  `};

  ${({ theme }) => theme.media.tabletLandscape`
    justify-content: unset;
  `};
`;
const Category = styled.div`
  align-self: center;
  font-size: ${({ theme }) => theme.fontSizes.base};
  color: ${({ theme }) => theme.colors.baseColor};
  border-bottom: 2px solid ${({ theme }) => theme.colors.borderBottomColor};
  transition: border-bottom 100ms ease-in-out, color 100ms ease-in-out;
`;
const SitemapList = styled.div`
  text-align: center;

  ${({ theme }) => theme.media.tabletLandscape`
    text-align: start;
  `};
`;
const SitemapLink = styled(Link)`
  display: block;
  padding: 0.4em 0;
  font-size: 0.9em;
  color: ${({ theme }) => theme.colors.baseColor};
  transition: color 100ms ease-in-out;

  &:active,
  &:focus,
  &:hover {
    color: gray;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tabletPortrait`
    display: grid;
    grid-template-columns: repeat(${({ categoryCount }) => categoryCount}, 1fr);
    grid-template-rows: auto;
    grid-row-gap: 1em;
  `};

  ${({ theme }) => theme.media.tabletLandscape`
    grid-template-columns: 30% repeat(${({ categoryCount }) =>
      categoryCount}, 1fr);
  `};
`;
const Metadata = styled.div`
  display: flex;
  grid-column: span ${({ categoryCount }) => categoryCount};
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.tabletLandscape`
    grid-column: unset;
    justify-content: space-between;
    align-items: unset;
  `};
`;
const OrganizationBrand = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2em;
  ${({ theme }) => theme.media.tabletLandscape`
    align-self: start;
    align-items: start;
  `};
`;

const BrandCaption = styled.span`
  font-size: 2em;
  font-weight: 400;
`;

const BrandSubtitle = styled.span``;

const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 0.8em;
  color: ${({ theme }) => theme.colors.baseColor};
  transition: color 100ms ease-in-out;
  margin-top: 1.125em;

  ${({ theme }) => theme.media.tabletLandscape`
    display: block;
    text-align: start;
  `};
`;
const HeartIcon = styled(Heart)`
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: ${({ theme }) => theme.colors.heartAccentColor};
`;
const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.backgroundColors.footerColor};
`;

const RenderSitemapCategories = () => {
  const { t } = useTranslation();

  return sitemapCategories.map(({ name, url = "", links }, idx) => (
    <Sitemap key={`${name}-${url}`}>
      <SitemapCategory key={name}>
        <Category>
          {!url ? <> {t(name)}</> : <Link to={url}> {t(name)}</Link>}
        </Category>
      </SitemapCategory>
      <SitemapList column={idx + 2}>
        {links.map(({ title: linkTitle, url: linkURL, external: ext }) => {
          return (
            <React.Fragment key={linkTitle}>
              {ext ? (
                <SitemapLinkExternal
                  key={`${linkTitle}-${linkURL}`}
                  href={linkURL}
                >
                  {t(linkTitle)}
                </SitemapLinkExternal>
              ) : (
                <SitemapLink key={`${linkTitle}-${linkURL}`} to={linkURL}>
                  {t(linkTitle)}
                </SitemapLink>
              )}
            </React.Fragment>
          );
        })}
      </SitemapList>
    </Sitemap>
  ));
};

const Footer = () => (
  <FooterContainer>
    <Content>
      <Grid categoryCount={sitemapCategories.length}>
        <Metadata categoryCount={sitemapCategories.length}>
          <OrganizationBrand>
            <BrandCaption>Sarah Kolb</BrandCaption>
            <BrandSubtitle>
              <Trans>Voice Actor</Trans>
            </BrandSubtitle>
          </OrganizationBrand>
          <Copyright>
            <div>&copy; {new Date().getFullYear()} Sarah Janina Kolb</div>
            <div>
              <Trans>
                Made with <HeartIcon></HeartIcon> in London
              </Trans>
            </div>
          </Copyright>
        </Metadata>
        <RenderSitemapCategories />
      </Grid>
    </Content>
  </FooterContainer>
);

export default Footer;
