import styled from "styled-components";
import ms from "../../util/ms";

const Actions = styled.div`
  > * {
    font-size: ${ms(2.5)};
    margin: 0.4em 0.25em;
  }

  > :first-child {
    ${({ theme }) => theme.media.tabletLandscape`
      margin-left: 0;
    `}
  }

  > :last-child {
    ${({ theme }) => theme.media.tabletLandscape`
      margin-right: 0;
    `}
  }
`;

export default Actions;
