const SITEMAP_LINKS = {
  SPOTLIGHT: {
    TITLE: "Spotlight",
    URL: "https://www.spotlight.com/profile/5471-0197-0417",
  },
  LINKEDIN: {
    TITLE: "Linkedin",
    URL: "https://www.linkedin.com/in/sarah-kolb-b183a4156",
  },
  CHATTERBOX: {
    TITLE: "Chatterbox Voices",
    URL:
      "https://chatterboxvoices.voicelist.online/de/favorites?uuid=561bb3e1-a581-4af5-b17e-66023bf688be",
  },
  VOICE_SQUAD: {
    TITLE: "Voice Squad",
    URL: "https://www.voicesquad.com/artist/sarah-kolb",
  },
  LONDON_VOICEOVER: {
    TITLE: "London Voiceover",
    URL: "https://www.londonvoiceover.com/artists/sarah-kolb/",
  },
  VOICEOVER_GALLERY: {
    TITLE: "The Voiceover Gallery",
    URL: "https://www.thevoiceovergallery.co.uk/voices/sarah-k/",
  },
  OUTSPOKEN: {
    TITLE: "Outspoken Voices",
    URL: "https://www.outspokenvoices.com/voice/4158/sarah",
  },
  VOQUENT: {
    TITLE: "Voquent",
    URL: "https://www.voquent.com/voice-actor/236426",
  },
  YOUTUBE: {
    TITLE: "YouTube",
    URL:
      "https://youtube.com/playlist?list=PLfajVvfVcac0tlwWMdqfI9sy1LDb8rJXo&si=LwB-G-oUAsfrp-ce",
  },
  GFTB: {
    TITLE: "Gravy For The Brain",
    URL: "https://sites.gravyforthebrain.com/website/sarah-kolb/",
  },
};

const INTERNAL_LINKS = {
  ABOUT: {
    TITLE: "About Me",
    URL: "/about",
  },
  RATES: {
    TITLE: "Rates",
    URL: "/rates",
  },
  CONTACT: {
    TITLE: "Contact",
    URL: "/contact",
  },
  IMPRINT: {
    TITLE: "Imprint",
    URL: "/imprint",
  },
};

const LINK_FREEPIK = "https://www.freepik.com/vectors/people";
const LINK_GRAVY = "https://rates.gravyforthebrain.com";
const LINK_SPRECHERPREISE = "http://www.sprecherpreise.de";

const EMAIL_SARAH = {
  USER: "hey",
  DOMAIN: "imsarahkolb.com",
};
const EMAIL_ANNA = {
  USER: "anna",
  DOMAIN: "voicesquad.com",
};
const EMAIL_KERRIE = {
  USER: "kerrie",
  DOMAIN: "londonvoiceover.com",
};

const YOUTUBE_LINKS = {
  MAIN: "https://www.youtube.com/watch?v=",
  OLD_VIDEOS: [
    "bt1vfynnZ_E",
    "ZtSvB5WS9sI",
    "QNZh2lvd5gs",
    "_PKkmDemD0M",
    "571bswHs-Z4",
    "yHrZqHjTKew",
    "XCuYoAkCf1E",
    "kkRnmPl1OdI",
  ],
  PLAYER_NAME: "Youtube Player",
};

const YOUTUBE_LINKS_GERMAN = {
  VIDEO_LIST: ["illILvoVFoQ", "_-b3bIhiQbw", "FPq44rypLy4", "J5xcR_fxP38"],
  VIDEO_TITLES: [
    "German Hertz Campaign Advert",
    "German Amazon Virtual Try-on Campaign",
    "ETS global iBT TOEFL Test Campaign Video",
    "DEKA Investment Fund Advert",
  ],
};

const YOUTUBE_LINKS_ENGLISH = {
  VIDEO_LIST: ["2PcQ1oNtEnQ", "T3AxfTfLZiM", "WnMMhbisvXE"],
  VIDEO_TITLES: [
    "Ben And Anna Deodorant Advert",
    "Liebherr Find Your Perfect Match",
    "SIX Young Talents Program",
  ],
};

export {
  EMAIL_KERRIE,
  EMAIL_ANNA,
  EMAIL_SARAH,
  INTERNAL_LINKS,
  LINK_FREEPIK,
  LINK_GRAVY,
  LINK_SPRECHERPREISE,
  SITEMAP_LINKS,
  YOUTUBE_LINKS,
  YOUTUBE_LINKS_GERMAN,
  YOUTUBE_LINKS_ENGLISH,
};
