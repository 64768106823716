import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 4em;
  ${({ reduceGap, theme }) =>
    reduceGap &&
    css`
      grid-row-gap: 2em;
      ${theme.media.tabletLandscape`
      grid-row-gap: 4em;
    `}
    `}
  ${({ noGap, theme }) =>
    noGap &&
    css`
      grid-row-gap: 0.5em;
      ${theme.media.tabletLandscape`
      grid-row-gap: 0em;
    `}
    `}
  justify-items: center;

  ${({ columnRatio, theme, verticalOnly, spaceBottom }) => theme.media
    .tabletLandscape`
    grid-template-columns: ${
      !verticalOnly && `${columnRatio[0]}fr ${columnRatio[1]}fr`
    };
    grid-column-gap: 2em;
    align-items: center;
    ${!spaceBottom && `grid-row-gap: 8em;`}
  `};
`;

const FeatureDuo = ({ children, columnRatio, ...passProps }) => (
  <Wrapper columnRatio={columnRatio} {...passProps}>
    {children}
  </Wrapper>
);

FeatureDuo.propTypes = {
  children: PropTypes.node.isRequired,
  columnRatio: PropTypes.arrayOf(PropTypes.number),
};

FeatureDuo.defaultProps = {
  columnRatio: [1, 1],
};

export default FeatureDuo;
