import styled from "styled-components";

const PlacementGroup = styled.g`
  ${(props) => `
    transform: ${props.pos.phone};
  `};

  ${(props) => props.theme.media.tabletLandscape`
    transform: ${props.pos.regular};
  `};
`;

export default PlacementGroup;
