import React, { useRef, useEffect, useState } from "react";
import { Link, useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { animated, useSpring } from "react-spring";
import { globalHistory } from "@reach/router";
import styled from "styled-components";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

import {
  TextLink,
  FlagLink,
  LogoLink,
  NavigationLinks,
  Content,
} from "../SubComponents";

import SiteLogo from "../../assets/images/svg/sitelogo2.svg";
import Menu from "../../assets/images/svg/menu.svg";
import DeSVG from "../../assets/images/svg/lang-de.svg";
import EnSVG from "../../assets/images/svg/lang-en.svg";

import em from "../../util/em";
import navigationItems, {
  navigationItemsMobile,
} from "../References/navigationItems";

const ContentPush = styled.div`
  height: ${({ height }) => em(height)};
  position: relative;
`;

const Nav = styled.nav`
  display: flex;
`;

const StyledHeader = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  height: ${({ height, heightPinned, isPinned }) =>
    isPinned ? `${em(heightPinned)}` : `${em(height)}`};
  width: 100%;
  z-index: 2;
  background-color: white;
  box-shadow: ${({ theme, isPinned }) =>
    isPinned ? `0 5px 10px 0 ${theme.backgroundColors.headerShadowColor}` : ""};
  transition: height 250ms ease-in-out 0s, box-shadow 250ms ease-in-out 0s;
  user-select: none;
`;

const ContentBox = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0.5em;
  ${({ theme }) => theme.media.phoneLandscape`
    padding: 0px 1.5em;
  `};
`;

const LogoBannerBox = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled(SiteLogo)`
  display: block;
  height: ${({ size }) => em(size * 0.75)};
  transition: height 100ms ease-in-out;
`;

const LogoCaption = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.mobileLogo};
  font-weight: 400;
  transition: opacity 100ms ease-in-out;
  opacity: ${({ isPinned }) => isPinned && 0};
  margin-left: 0.2775em;
  width: 50%;
  line-height: 1;
  ${({ theme }) => theme.media.tabletPortrait`
    width:100%;
    line-height: initial;
    margin-left: 1.5em;
    font-size: ${({ theme }) => theme.fontSizes.logo};
  `};
`;

const SlideMenuBox = styled.div`
  position: absolute;
  top: ${({ isPinned }) => (isPinned ? em(60) : em(80))};
  width: 100%;
  background-color: white;
  text-align: center;
  z-index: 2;
  overflow-y: auto;
  border-radius: 0 0 9em 9em;
  ${({ theme }) => theme.media.tabletLandscape`
    display: none;
  `};
`;

const AnimatedSlideMenuBox = animated(SlideMenuBox);

const SlideMenuNavList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 2em;

  * {
    margin: 1em;
  }
`;

const SlideMenuToggle = styled.button`
  user-select: none;
  cursor: pointer;
  pointer-events: auto;
  border: 0;
  border-radius: 0.25em;
  background-color: transparent;
  transition: background-color 300ms ease-in-out;

  &:active,
  &:focus,
  &:hover {
    outline: 0;
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.backgroundColors.lightColor};
  }

  > * {
    width: 2.5em;
    height: 2.5em;
    vertical-align: middle;
  }

  ${({ theme }) => theme.media.tabletLandscape`
    display: none;
  `};
`;

const RenderedSlideMenuNavListItems = React.memo((props) => {
  const { t } = useTranslation();

  return navigationItemsMobile.map(({ title, url }) => (
    <TextLink key={`${title}-${url}`} to={url} {...props}>
      {t(title)}
    </TextLink>
  ));
});

const LinkList = React.memo((props) => {
  const { t } = useTranslation();

  return navigationItems.map(({ title, url }) => (
    <TextLink key={`${title}-${url}`} to={url} {...props}>
      {t(title)}
    </TextLink>
  ));
});

const FlagToggle = () => {
  const { t } = useTranslation();
  const { language, languages, originalPath } = useI18next();
  const toggleLanguageTo = languages.find((lang) => lang !== language);

  return (
    <FlagLink
      aria-label={t("Switch language to German")}
      to={originalPath}
      language={toggleLanguageTo}
    >
      {toggleLanguageTo === "en" ? <EnSVG /> : <DeSVG />}
    </FlagLink>
  );
};

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [slideMenuOpen, setSlideMenuOpen] = useState(false);
  const slideMenuElementRef = useRef(null);

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 60 ? true : false);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(
    /**
     * Using undocumented globalHistory export from @reach
     * https://github.com/reach/router/blob/master/src/lib/history.js
     */
    () =>
      globalHistory.listen(({ action }) => {
        if (action === "PUSH") {
          clearAllBodyScrollLocks();
        }
      }),
    [],
  );

  const fullMenuAnimation = useSpring({
    height: slideMenuOpen ? `110vh` : `0vh`,
  });

  const handleSlideMenuToggle = () => {
    if (!slideMenuOpen) {
      disableBodyScroll(slideMenuElementRef);
    } else {
      enableBodyScroll(slideMenuElementRef);
    }
    setSlideMenuOpen(!slideMenuOpen);
  };

  return (
    <>
      <ContentPush height={80} />
      <StyledHeader height={80} heightPinned={60} isPinned={scrolled}>
        <ContentBox>
          <LogoBannerBox>
            <LogoLink aria-label={"Sarah Kolb Logo"} to={"/"}>
              <Logo size={scrolled ? 80 : 100} />
            </LogoLink>
            <LogoCaption isPinned={scrolled}>
              <Link to={"/"}>SARAH KOLB</Link>
            </LogoCaption>
          </LogoBannerBox>
          <Nav>
            <NavigationLinks>
              <LinkList />
              <FlagToggle />
            </NavigationLinks>
            <SlideMenuToggle
              aria-label={"Menu"}
              onClick={handleSlideMenuToggle}
            >
              <Menu />
            </SlideMenuToggle>
          </Nav>
        </ContentBox>
        <AnimatedSlideMenuBox
          ref={slideMenuElementRef}
          isOpen={slideMenuOpen}
          isPinned={scrolled}
          style={fullMenuAnimation}
        >
          <SlideMenuNavList>
            <RenderedSlideMenuNavListItems />
            <FlagToggle />
          </SlideMenuNavList>
        </AnimatedSlideMenuBox>
      </StyledHeader>
    </>
  );
};

export default Header;
