import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "gatsby-plugin-react-i18next";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from "gatsby";

import metaBanner from "../../assets/images/png/metaimage.png";

function SEO({ description, meta, title, path }) {
  const { t } = useTranslation();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            sarahKolb {
              author
              description
              title
              subtitle
              ogLangAlt
              ogLangPrimary
              ogType
            }
            socialInfo {
              imageDescription
              twitterCard
              twitterHandle
            }
            siteUrl
          }
        }
      }
    `,
  );

  const metaDescription =
    t(description) || t(site.siteMetadata.sarahKolb.description);

  const bannerDescription = t(site.siteMetadata.socialInfo.imageDescription);

  return (
    <Helmet
      title={title}
      defaultTitle={`${t(site.siteMetadata.sarahKolb.title)} ${t(
        site.siteMetadata.sarahKolb.subtitle,
      )}`}
      titleTemplate={`${t(site.siteMetadata.sarahKolb.title)} ${t(
        site.siteMetadata.sarahKolb.subtitle,
      )} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `author`,
          content: site.siteMetadata.sarahKolb.author,
        },
        {
          property: `creator`,
          content: site.siteMetadata.sarahKolb.author,
        },
        {
          property: `publisher`,
          content: site.siteMetadata.sarahKolb.author,
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}${path}`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: site.siteMetadata.sarahKolb.ogLangPrimary,
        },
        {
          property: `og:locale:alternate`,
          content: site.siteMetadata.sarahKolb.ogLangAlt,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${metaBanner}`,
        },
        {
          property: `og:image:type`,
          content: `image/png`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          property: `og:type`,
          content: site.siteMetadata.sarahKolb.ogType,
        },
        {
          name: `twitter:card`,
          content: site.siteMetadata.socialInfo.twitterCard,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.sarahKolb.author,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.socialInfo.twitterHandle,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `${site.siteMetadata.siteUrl}${metaBanner}`,
        },
        {
          name: `twitter:image:alt`,
          content: bannerDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
